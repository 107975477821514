header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* Language Switcher Styles */
.language-switcher {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 1000;
}

.btn-language {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    border: 1px solid var(--color-primary);
    transition: all 0.3s ease;
    color: var(--color-white);
    cursor: pointer;
}

.btn-language:hover {
    background: var(--color-primary);
    color: var(--color-bg);
    box-shadow: 0 0 10px var(--color-primary);
}

.language-icon {
    padding-top: 0.2rem;
    font-size: 1.0rem;
}

.chevron-icon {
    transition: transform 0.3s ease;
}

.language-switcher:hover .chevron-icon {
    transform: rotate(180deg);
}

.language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: var(--color-bg-variant);
    border: 1px solid var(--color-primary);
    border-radius: 8px;
    padding: 0.5rem;
    margin-top: 0.5rem;
    min-width: 120px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.language-item {
    width: 100%;
    padding: 0.5rem 1rem;
    background: transparent;
    border: none;
    color: var(--color-white);
    text-align: left;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    white-space: nowrap;
}

.language-item:hover {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* Responsive */
@media screen and (max-width: 600px) {
    .language-switcher {
        top: -20px;
        right: -10px;
    }
    
    .btn-language {
        padding: 0.2rem 0.4rem;
        font-size: 0.9rem;
;
    }
    
    .language-dropdown {
        min-width: 100px;
    }
    
    .language-item {
        padding: 0.2rem 0.4rem;
        font-size: 0.9rem;
    }
}


.header_container h1 {
    font-size: 3rem;
}

/* ***** CTA ******* */

.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
  }
  
  .dropdown {
    position: relative; 
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%; 
    left: 0;
    background: #43597d;
    border: 1px solid var(--color-light);
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; 
    min-width: 150px; 
    transform: translateY(8px); 
    transition: opacity 0.3s ease, transform 0.3s ease; 
   
}



  
  .dropdown-item {
    display: flex; 
    align-items: center; 
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: var(--color-dark);
    transition: background 0.3s ease;
    font-size: 0.9rem; 
  }
  
  .dropdown-item:hover {
    background: var(--color-primary-light);
    color: lightblue;
  }

  .btnd {
    height: 55px;
  }
  
  









/* ***** Socials ******* */


.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials:after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.header_socials a {
    font-size: 2rem;
}

/* ***** ME ******* */

.me {
   
    width: 22rem;
    height:30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 1rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 4rem 1.5rem 1.5rem;
}



/* ***** SCROLL DOWN ******* */


.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 4rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}


/* MEDIA QUERIES - MEDIUM DEVCIES */

@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }

   
    
}

/* MEDIA QUERIES - SMALL DEVCIES */

@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    header_socials,
    scroll_down {
        display: none;
    }
    


}

@media screen and (max-width: 465px) {
   

    .me {
   
        width: 14rem;
        height:30rem;
        position: absolute;
        left: calc(50% - 6rem);
        margin-top: 1rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 1rem 1rem 1rem;
    }
    
}