nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    right: 1rem; /* Postavlja `nav` na desnu stranu */
    top: 50%; /* Postavlja `nav` na sredinu vertikalno */
    transform: translateY(-50%); /* Centriranje po vertikalnoj osi */
    display: flex;
    flex-direction: column; /* Postavlja elemente vertikalno */
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    align-items: center;
    justify-content: center;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
}

nav a.active {
    background: var(--color-bg);
    color: var(--color-white);
}

@media (max-width: 768px) {
    nav {
        width: 100%;
        position: fixed;
        top: 0; /* Postavlja `nav` na vrh stranice */
        left: 0;
        transform: none; /* Uklanja transformaciju */
        flex-direction: row; /* Postavlja elemente horizontalno */
        justify-content: space-around; /* Raspoređuje elemente sa razmakom */
        border-radius: 0; /* Uklanja zaobljene ivice */
        padding: 0.5rem 0; /* Manje padding za horizontalni raspored */
    }
}

