.container.contact_container {
    width: 68%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact_options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact_option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 1.2rem;
}

/*============= FORM ====================*/
.form {
 
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center; /* Centriranje stavki */
  width: 100%;
  max-width: 600px; /* Ograničavanje širine forme */
  margin: 0 auto; /* Centriranje forme na stranici */
  padding: 1rem;
}

input, textarea {
  font-family: "Merienda", sans-serif;
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  box-sizing: border-box; /* Osiguranje da padding ne prelazi širinu */
}

input::placeholder {
  color: #a0c4ff; /* Postavite željenu boju */
  opacity: 1; /* Postavite na 1 da bi boja bila puna */
}

/* Stilizovanje placeholder-a za textarea elemente */
textarea::placeholder {
  color: #a0c4ff; /* Postavite željenu boju */
  opacity: 1; /* Postavite na 1 da bi boja bila puna */
}



button {
  font-family: "Merienda", sans-serif;
}



.btn:hover {
  background-color: #0056b3; /* Promena boje pozadine na hover */
}

.btn:active {
  transform: scale(0.88); /* Skaliranje dugmeta na klik */
}




/* MEDIA QUERIES - MEDIUM DEVCIES */

@media screen and (max-width: 1024px) {
  .container.contact_container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}



/* MEDIA QUERIES - SMALL DEVCIES */

@media screen and (max-width: 600px) {
    .container.contact_container {
        width: var(--container-width-sm);
        
      }
}